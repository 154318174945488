/* ===========================
   1. Font Import
   =========================== */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* ===========================
         2. CSS Variables & Base Setup
         =========================== */
:root {
  --base-font-size: 16px;

  /* Light (Beige) Mode Colors */
  --light-bg-start: #f7f2ea;
  --light-bg-end: #f2ebe0;
  --light-text: #5a4635;
  --light-bubble-ai: #fcfbf6;
  --light-bubble-user: #d8bca7;
  --light-bubble-user-text: #3e2f24;
  --light-input-bg: #f2ebe0;

  /* 
          Refined Dark Mode Colors - warm, subtle, 
          and easier on the eyes
        */
  --dark-bg-start: #2c2b2a;
  /* top of gradient */
  --dark-bg-end: #3a3837;
  /* bottom of gradient */
  --dark-text: #eae7dc;
  /* warm off-white text */
  --dark-bubble-ai: #3f3d3c;
  --dark-bubble-user: #5a5654;
  --dark-bubble-user-text: #f2efeb;
  --dark-input-bg: #3c3a39;

  /* Bubble/Message Styling */
  --bubble-radius: 16px;
  --bubble-padding: 0.65rem 1.25rem;

  /* Transitions */
  --transition-speed: 0.3s;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--light-bg-start);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: clamp(14px, 1.1vw, var(--base-font-size));
}

.yes-therapy-container {
  height: calc(var(--vh) * 100);
  display: flex;
  flex-direction: column;
  transition: background-color var(--transition-speed) ease,
    color var(--transition-speed) ease;
}

.yes-therapy-container.light {
  background: linear-gradient(to bottom, var(--light-bg-start), var(--light-bg-end));
  color: var(--light-text);
}

.yes-therapy-container.dark {
  background: linear-gradient(to bottom, var(--dark-bg-start), var(--dark-bg-end));
  color: var(--dark-text);
}

/* =====================
         HEADER
      ===================== */
.main-header {
  position: relative;
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(6px);
}

.yes-therapy-container.dark .main-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.title {
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 1.3rem;
}

.insights-button {
  position: absolute;
  top: 1rem;
  right: 5rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.95rem;
  padding: 6px 10px;
  border-radius: 4px;
  transition: background-color 0.15s ease, color 0.15s ease;
  color: inherit;
}

.insights-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.yes-therapy-container.dark .insights-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.settings-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  outline: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.15s ease, opacity 0.15s ease;
}

.settings-button img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.settings-button:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

/* =====================
         INSIGHTS MODAL
      ===================== */
.insights-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  animation: fadeIn 0.3s ease forwards;
}

.insights-modal-content {
  background: #fff;
  border-radius: 1rem;
  max-width: 400px;
  width: 90%;
  padding: 1.5rem 1.5rem 2rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  animation: scaleUp 0.3s ease forwards;
  position: relative;
  color: #333;
}

.yes-therapy-container.dark .insights-modal-content {
  background: #333;
  color: #eee;
}

.insights-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.insights-header h2 {
  margin: 0;
  font-weight: 600;
}

.close-insights {
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  transition: transform 0.15s ease, opacity 0.15s ease;
}

.close-insights:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* =====================
         SETTINGS MODAL
      ===================== */
.settings-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease forwards;
}

.settings-modal-content {
  background: #fff;
  border-radius: 1rem;
  max-width: 400px;
  width: 90%;
  padding: 1.5rem 1.5rem 2rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  animation: scaleUp 0.3s ease forwards;
  position: relative;
}

.yes-therapy-container.dark .settings-modal-content {
  background: #333;
  color: #eee;
}

.settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.settings-header h2 {
  margin: 0;
  font-weight: 600;
}

.close-settings {
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  transition: transform 0.15s ease, opacity 0.15s ease;
}

.close-settings:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.settings-option {
  margin: 1.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #c6c6c6;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked+.slider {
  background-color: #2196f3;
}

.switch input:checked+.slider:before {
  transform: translateX(20px);
}

.delete-data {
  color: #333;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.9rem;
  margin: 0.5rem 0;
  text-align: center;
  display: inline-block;
  transition: color 0.2s ease;
}

.delete-data:hover {
  color: #666;
  text-decoration: none;
}

.delete-success {
  margin-top: 0.5rem;
  color: green;
  font-size: 0.9rem;
  text-align: center;
}

.settings-note {
  font-size: 0.85rem;
  opacity: 0.8;
  margin-top: 0.5rem;
}

.settings-modal-content button {
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  border: 1px solid #999;
  background: none;
  border-radius: 6px;
  transition: background-color 0.2s ease, transform 0.2s ease;
  margin-bottom: 0.5rem;
}

.settings-modal-content button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.02);
}

.yes-therapy-container.dark .settings-modal-content button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* =====================
         ONBOARDING MODAL
      ===================== */
.onboarding-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  animation: fadeIn 0.3s ease forwards;
}

.onboarding-content {
  background: #fff;
  border-radius: 1rem;
  max-width: 500px;
  width: 90%;
  padding: 2rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  animation: scaleUp 0.3s ease forwards;
  position: relative;
  text-align: center;
}

.yes-therapy-container.dark .onboarding-content {
  background: #333;
  color: #eee;
}

.onboarding-content h2 {
  margin-top: 0;
  font-weight: 600;
}

.close-onboarding-button {
  margin-top: 1.5rem;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background-color: #2196f3;
  color: #fff;
  font-size: 1rem;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.close-onboarding-button:hover {
  background-color: #167ac1;
  transform: scale(1.05);
}

/* =====================
         CHAT WINDOW
      ===================== */
.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;
  max-width: 768px;
  margin: 0 auto;
}

.temp-chat-info {
  text-align: center;
  margin: 0.5rem 0 1rem;
  font-size: 0.9rem;
  opacity: 0.85;
  line-height: 1.4;
}

.message-bubble {
  display: inline-block;
  position: relative;
  margin-bottom: 1.1rem;
  padding: var(--bubble-padding);
  border-radius: var(--bubble-radius);
  word-wrap: break-word;
  line-height: 1.5;
  transition: background-color var(--transition-speed) ease,
    transform 0.15s ease;
  width: auto;
  max-width: 70%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
}

.fade-in-message {
  animation: fadeInUp 0.3s ease forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .message-bubble {
    max-width: 90%;
  }
}

.message-bubble:hover {
  transform: translateY(-2px);
}

/* AI messages: left side */
.message-bubble.ai {
  align-self: flex-start;
}

.yes-therapy-container.light .message-bubble.ai {
  background-color: var(--light-bubble-ai);
  color: #333;
}

.yes-therapy-container.dark .message-bubble.ai {
  background-color: var(--dark-bubble-ai);
  color: var(--dark-text);
}

/* User messages: right side */
.message-bubble.user {
  align-self: flex-end;
}

.yes-therapy-container.light .message-bubble.user {
  background-color: var(--light-bubble-user);
  color: var(--light-bubble-user-text);
}

.yes-therapy-container.dark .message-bubble.user {
  background-color: var(--dark-bubble-user);
  color: var(--dark-bubble-user-text);
}

.bubble-label {
  font-size: 0.75rem;
  font-weight: 600;
  opacity: 0.7;
  margin-bottom: 0.2rem;
}

.bubble-text {
  font-size: 0.95rem;
}

.bubble-text ul,
.bubble-text ol {
  margin: 0.5em 0 0.5em 1.4em;
  padding-left: 0;
}

.bubble-text li {
  margin-bottom: 0.3em;
}

.bubble-text blockquote {
  margin: 0.6em 0;
  padding-left: 1em;
  border-left: 3px solid rgba(0, 0, 0, 0.2);
  opacity: 0.85;
}

.yes-therapy-container.dark .bubble-text blockquote {
  border-left: 3px solid rgba(255, 255, 255, 0.2);
}

.typing-indicator {
  text-align: left;
  font-style: italic;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  opacity: 0.8;
}

.dot {
  display: inline-block;
  font-weight: bold;
  margin-left: 2px;
}

.dot1 {
  animation: blink 1s infinite;
}

.dot2 {
  animation: blink 1s infinite 0.2s;
}

.dot3 {
  animation: blink 1s infinite 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* 
      Because we position absolute modals,
      let's ensure we don't lose them behind Chat 
   */
.auth-overlay,
.feedback-overlay {
  position: fixed;
  /* changed from absolute */
}

/* =====================
         AUTH OVERLAY
      ===================== */
.auth-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-container {
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2);
}

.yes-therapy-container.dark .auth-container {
  background: #444;
  color: #eee;
}

.auth-container h2 {
  margin-top: 0;
  font-weight: 600;
}

.button-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.auth-button {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  background: #2196f3;
  color: #fff;
  border: none;
  border-radius: 0.4rem;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.auth-button:hover {
  background-color: #167ac1;
  transform: scale(1.03);
}

.auth-button:disabled,
.auth-button.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  transform: none;
}

.auth-button:disabled:hover,
.auth-button.disabled:hover {
  background-color: #ccc;
  transform: none;
}

.auth-error {
  color: #c0392b;
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: left;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 1.5rem;
}

.auth-form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.auth-form label {
  text-align: left;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.95rem;
}

.auth-form input[type="email"],
.auth-form input[type="password"] {
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.auth-form input[type="email"]:focus,
.auth-form input[type="password"]:focus {
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

/* =====================
         STICKY INPUT BAR
      ===================== */
.input-bar-container {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: var(--light-input-bg);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.yes-therapy-container.dark .input-bar-container {
  background: var(--dark-input-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.input-bar {
  display: flex;
  align-items: center;
  max-width: 768px;
  margin: 0 auto;
  padding: 0.6rem;
}

.chat-input {
  flex: 1;
  border: none;
  border-radius: 2rem;
  padding: 0.6rem 1rem;
  outline: none;
  font-size: 1rem;
  color: inherit;
  background-color: #f1f1f1;
  line-height: 1.4;
  overflow-y: hidden;
  resize: none;
  min-height: 1.75em;
  max-height: 6rem;
}

.chat-input::placeholder {
  color: #aaa;
}

.yes-therapy-container.dark .chat-input {
  background-color: #444;
  color: #eee;
}

.yes-therapy-container.dark .chat-input::placeholder {
  color: #777;
}

.mic-button,
.send-button {
  border: none;
  background: none;
  cursor: pointer;
  margin: 0 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 44px;
  height: 44px;
  font-size: 1.2rem;
  color: inherit;
  transition: transform 0.15s ease, background-color 0.15s ease, color 0.15s ease;
  border-radius: 50%;
}

.mic-button:hover,
.send-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.06);
}

.yes-therapy-container.dark .mic-button:hover,
.yes-therapy-container.dark .send-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.send-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Mic button "recording" pulse effect */
.mic-button.active svg {
  color: #ff4e42;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

/* Loading spinner (transcribing) */
.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #2196f3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 0.3rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Scroll-down button */
.scroll-down-button {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, transform 0.2s ease;
  z-index: 20;
}

.scroll-down-button:hover {
  background-color: rgba(0, 0, 0, 0.35);
  transform: translate(-50%, -2px);
}

.yes-therapy-container.dark .scroll-down-button {
  background-color: rgba(255, 255, 255, 0.15);
  color: #eee;
}

.yes-therapy-container.dark .scroll-down-button:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

/* =====================
         FEEDBACK MODALS
      ===================== */
.feedback-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;
  animation: fadeIn 0.3s ease forwards;
}

.feedback-modal-content {
  background: #fff;
  color: #333;
  border-radius: 1rem;
  max-width: 420px;
  width: 90%;
  padding: 1.5rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  animation: scaleUp 0.3s ease forwards;
  position: relative;
}

.yes-therapy-container.dark .feedback-modal-content {
  background: #333;
  color: #eee;
}

.feedback-modal-content h3 {
  margin-top: 0;
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 1.2rem;
}

.feedback-modal-content p {
  margin-bottom: 1rem;
  font-size: 0.95rem;
  line-height: 1.4;
}

.feedback-modal-content textarea {
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid #ccc;
  font-size: 0.95rem;
  padding: 0.75rem;
  resize: none;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 1rem;
}

.yes-therapy-container.dark .feedback-modal-content textarea {
  background: #444;
  color: #eee;
  border: 1px solid #666;
}

.feedback-modal-content textarea:focus {
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

.feedback-button-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.feedback-button-row button {
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.feedback-button-row button:hover {
  transform: scale(1.02);
}

.feedback-button-row button:first-child {
  background-color: #ccc;
  color: #333;
}

.feedback-button-row button:last-child {
  background-color: #2196f3;
  color: #fff;
}

.feedback-slider {
  width: 100%;
  margin: 1rem 0 0.5rem;
}

.feedback-slider-labels {
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

/* ============================
      3. TTS Button & Spinner Additions
      ============================ */

/* 
      Each message row uses flex 
      so we can place the TTS button outside the bubble 
   */
.message-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.1rem;
  position: relative;
}

.message-row.user {
  justify-content: flex-end;
}

.message-row.ai {
  justify-content: flex-start;
}

/* Hide TTS for user messages so it doesn't appear on the right bubble */
.message-row.user .tts-button {
  display: none;
}

/* TTS button styling to match the rest of the UI */
.tts-button.fancy-tts-button {
  margin-left: 0.5rem;
  margin-right: 0.2rem;
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  transition: color var(--transition-speed) ease,
    transform var(--transition-speed) ease;
  padding: 0.4rem;
  font-size: 1.25rem;
  align-self: center;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tts-button.fancy-tts-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.07);
}

.yes-therapy-container.dark .tts-button.fancy-tts-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.tts-icon {
  display: block;
  width: 20px;
  height: 20px;
}

/* Spinner for TTS loading */
.loading-tts {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  background: none;
  border: none;
  cursor: not-allowed;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
}

/* A ring spinner specifically for TTS loading. 
      This smaller ring is visually consistent with the existing style. */
.tts-loading-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top: 3px solid #2196f3;
  border-radius: 50%;
  animation: spinnerRotate 0.85s linear infinite;
}

.yes-therapy-container.dark .tts-loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top: 3px solid #d2d2d2;
  /* a lighter ring top in dark mode */
}

@keyframes spinnerRotate {
  to {
    transform: rotate(360deg);
  }
}